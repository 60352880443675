import { MvRegion } from '@/lib/strapi-types/MvRegion';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindRegionsProps {
  filters?: Record<string, unknown>;
  populate?: string[];
}

export async function useFindRegions({ filters = {}, populate }: UseFindRegionsProps = {}) {
  const defaultPopulate = ['monthWeather'];
  const allPopulate = populate ? [...defaultPopulate, ...populate] : defaultPopulate;

  const parsedQuery = stringify({
    populate: allPopulate,
    filters
  });

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvRegion['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.REGIONS)}?${parsedQuery}`
  );

  return { regions: data.value?.data ?? [] };
}
